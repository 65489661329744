<template>
  <v-layout column>
    <FieldHeader
      v-if="!hideHeader"
      :field="field"
      :patient="patient"
      :session.sync="session"
      :owner="owner"
      :hideCollapseBtn="hideCollapseBtn"
      :collapsed="hideBody"
      :name="name"
      @history="onHistory"
      @collapse="onCollapse"
    ></FieldHeader>
    <v-layout v-if="!disabled && !hideBody" column>
      <LabelDateField
        :value.sync="date"
        :syncId="syncId"
        :autosave="autosave"
        hideDetails
        noMargins
      >
      </LabelDateField>
      <DateFormatErrorMessage :value="date" allowEmpty></DateFormatErrorMessage>
    </v-layout>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :hideEdit="hideEdit"
      :session="session"
      :owner="owner"
      @edit="onEdit"
      @append="onAppend"
      @copy="onCopy"
    >
      <div
        v-html="field.data.date || '-'"
        class="text-body-1 pch-text my-2 black--text"
      ></div>
    </DisabledFieldWrapper>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "patientCard",
    },
    clientOnlyPredefined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    date: {
      get() {
        return this.field.data.date;
      },
      set(value) {
        this.$set(this.field.data, "date", value);
      },
    },
  },
  components: {
    DateFormatErrorMessage: () =>
      import("@/components/controls/DateFormatErrorMessage"),
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
    PredefinedRecordMenu: () =>
      import("@/components/menus/PredefinedRecordMenu"),
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
    LabelDateField: () => import("@/components/LabelDateField"),
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onHistory() {
      this.$emit("history");
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
    onCopy() {
      this.$emit("copy");
    }
  },
  mounted() {
    if (!this.disabled && this.autosave) {
      this.startWatching("date");
    }
  },
};
</script>

<style lang="scss" scoped>
.pch-text-wrapper {
  border-radius: 15px;
  background-color: white;
}
.pch-text {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.pch-text ::v-deep p {
  margin-bottom: 0 !important;
}
.default-shadow {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
</style>